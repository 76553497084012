<template>
    <div v-if="type == 'home'" class="home-icon-decorate">
        <svg-icon class-name="icon-hanbao" icon="icon_hanbao" width="170" height="170"/>
        <svg-icon class-name="icon-youtiao" icon="icon_youtiao" width="200" height="200"/>
        <svg-icon class-name="icon-pisa" icon="icon_pisa" width="220" height="220"/>
    </div>
    <div v-else class="login-icon-decorate">
        <svg-icon class-name="icon-hanbao" icon="icon_hanbao" width="200" height="200"/>
        <svg-icon class-name="icon-youtiao" icon="icon_hanbao" width="200" height="200"/>
        <svg-icon class-name="icon-pisa" icon="icon_pisa" width="200" height="200"/>
    </div>

</template>

<script>
    export default {
        name: "BGDecorate",
        props: {
            type: {
                value: "home",
                type: String
            }
        }
    }
</script>

<style scoped lang="less">

    .home-icon-decorate {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -ms-overflow: hidden;

        .icon-hanbao {
            position: absolute;
            z-index: -10;
            right: -80px;
            top: 5%;
            transform: rotate(-30deg);
            opacity: 0.3;
            -webkit-animation: rotateTrans 8s both infinite;
            -moz-animation: rotateTrans 8s both infinite;
            animation: rotateTrans 8s both infinite;
        }

        .icon-youtiao {
            position: absolute;
            right: -50px;
            z-index: -10;
            bottom: -50px;
            opacity: 0.3;
            transform: rotate(30deg);
            -webkit-animation: rotateTrans 10s both infinite;
            -moz-animation: rotateTrans 10s both infinite;
            animation: rotateTrans 10s both infinite;
        }

        .icon-pisa {
            position: absolute;
            left: -80px;
            z-index: -10;
            top: 25%;
            opacity: 0.3;
            -webkit-animation: rotateTrans 12s both infinite;
            -moz-animation: rotateTrans 12s both infinite;
            animation: rotateTrans 12s both infinite;
        }
    }

    /*装饰*/
    .login-icon-decorate {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -ms-overflow: hidden;
        z-index: -1;


        .icon-hanbao {
            position: absolute;
            z-index: -10;
            left: -30px;
            top: 50%;
            transform: rotate(-30deg);
            opacity: 0.3;
            -webkit-animation: rotateTrans 5s both infinite;
            -moz-animation: rotateTrans 5s both infinite;
            animation: rotateTrans 5s both infinite;
        }

        .icon-youtiao {
            position: absolute;
            right: -80px;
            z-index: -10;
            top: 15%;
            opacity: 0.3;
            transform: rotate(30deg);
            -webkit-animation: rotateTrans 5s both infinite;
            -moz-animation: rotateTrans 5s both infinite;
            animation: rotateTrans 5s both infinite;
        }

        .icon-pisa {
            position: absolute;
            right: -50px;
            z-index: -10;
            bottom: -10px;
            opacity: 0.3;
            -webkit-animation: rotateTrans 5s both infinite;
            -moz-animation: rotateTrans 5s both infinite;
            animation: rotateTrans 5s both infinite;
        }
    }
</style>
