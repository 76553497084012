<template>
    <div style="position: relative">
        <div class="main-bg">
            <div class="main-bg-title">
                <span>道县朝阳学校</span><br/>
                <span class="main-bg-title-sub">欢迎您！</span>
            </div>
            <svg-icon class-name="label-icon" icon="icon_miantiao" width="257" height="145"/>

            <div class="main-bg-logout-icon">
                <i class="main-bg-logout-icon-i el-icon-switch-button" @click="callLogout"/>
            </div>
        </div>

        <div class="main-card">

            <div class="main-card-decorate">
                <b-g-decorate type="home"></b-g-decorate>
            </div>
            <!--餐费支付-->
            <div class="home-icon home-icon-pay" v-anim-click @animationend="toChargePage">
                <svg-icon icon="icon_home_pay" width="58" height="58"/>
                <span class="home-icon-label">我要缴费</span>
            </div>

            <!--餐费支付-->
            <div class="home-icon home-icon-order" v-anim-click @animationend="toOrderPage">
                <svg-icon icon="icon_home_details" width="58" height="58"/>
                <span class="home-icon-label">我的订单</span>
            </div>

        </div>

    </div>
</template>

<script>

    import BGDecorate from "@/components/BGDecorate";

    export default {
        name: "HomePage",
        components: {BGDecorate},
        methods: {
            toChargePage() {
                console.log("1111")
                this.$router.push({path: "/charge"})
            },
            toOrderPage() {
                this.$router.push({path: "/order"})
            },
            callLogout() {
                this.$store.commit('logout')
                this.$router.push({path: "/"})
            }
        }
    }
</script>

<style scoped lang="less">


    .main-card {
        position: absolute;
        width: 100%;
        height: calc(100vh - 195px);
        background: #FFFFFF;
        top: 195px;
        box-shadow: 0px -4px 100px #E5E5E5;
        border-radius: 50px 50px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-decorate {
            position: absolute;
            top: 0px;
            bottom: 0px;
            width: 100%;
            z-index: 0;
        }

        /*图标*/

        .home-icon {
            width: 137px;
            height: 137px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            margin-top: 50px;
            box-shadow: 23.1299px -17.7922px 33.8052px rgba(0, 0, 0, 0.25);
            z-index: 10;

            &-label {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18.8655px;
                line-height: 22px;
                color: white;
            }

            &-pay {
                background: linear-gradient(140.22deg, #FFC530 -4.96%, #FF0000 156.44%);
            }

            &-order {
                left: 119px;
                top: 518px;
                background: linear-gradient(140.22deg, #F9B508 -4.96%, #FFC530 42.92%, #F49898 98.59%);
            }
        }
    }


    .main-bg {
        position: relative;
        width: 100%;
        height: 100vh;
        background: linear-gradient(155.73deg, #E66720 23.84%, rgba(244, 45, 45, 0) 66.2%);

        &-title {
            position: absolute;
            left: 30px;
            top: 40px;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 26.9755px;
            line-height: 32px;
            /* identical to box height */
            color: #FFFFFF;
            text-align: left;

            &-sub {
                font-size: 18.9755px;
                line-height: 22px;
            }
        }

        &-logout-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 25px;
            color: white;
        }

        .label-icon {
            position: absolute;
            top: 13px;
            left: 53px;
        }
    }
</style>
